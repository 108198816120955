/* media queries */
/* iPadPro needs work */
// $iPadPro: "(min-device-width: 1024px) and (max-device-width: 1366px)";
// $tablet: "(min-width: 768px) and (max-width: 1024px)";
// $tabletLandscape: "(min-width: 768px) and (max-width: 1024px) and (orientation : landscape)";
// $desktop: "(min-width: 1024px)";
// $phone: "(min-device-width: 320px) and (max-device-width: 736px)";
// $phone6: "(min-device-width: 375px) and (max-device-width: 667px)";
// $phone6Plus: "(min-device-width: 414px) and (max-device-width: 736px)";

// New Media Queries
$iPadPro: "(min-width: 1024px) and (max-width: 1366px)";
$tablet: "(min-width: 768px) and (max-width: 1024px)";
$tabletLandscape: "(min-width: 768px) and (max-width: 1024px) and (orientation : landscape)";
$desktop: "(min-width: 1024px)";
$phone: "(min-width: 320px) and (max-width: 736px)";
$phone6: "(min-width: 375px) and (max-width: 667px)";
$phone6Plus: "(min-width: 414px) and (max-width: 736px)";

$mobile767: "(max-width: 767px)";
$mobile367: "(max-width: 367px)";
// New Media Queries 

// Font Family

// Default
$Arial: Arial, Helvetica, sans-serif;
$CourierNew: 'Courier New', Courier, monospace;
$Georgia: Georgia, serif;
$LucidaSansUnicode: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
$Tahoma: Tahoma, Geneva, sans-serif;
$TimesNewRoman: 'Times New Roman', Times, serif;
$TrebuchetMs: 'Trebuchet MS', Helvetica, sans-serif;
$Verdana: Verdana, Geneva, sans-serif;
// Default

// Custom
$SFProDisplay: 'SF Pro Display';
$SFProText: 'SF Pro Text';
// Font Family


// Color
$purple: #C800FF;
$secondaryColor: #5ABDCE;
$thirdColor: #F04533;
$pink: #F57B8C;
$green-logo: #00E1E1;
$green: #4BB543;
$lightgreen: #F7FAFC;
$white: #fff;
$black: #000;
$lightblack: #1A1F36;
$red: #EA1414;
$gray: #979797;
$lightgray: #D9DCE1;
// Color

// font Weight
$xlbold: 900;
$exbold: 800;
$bold: 700;
$semibold: 600;
$medium: 500;
$normal: 400;
// font Weight

$base: '../images/';
$fontbase: 1rem;

@mixin list-style-none {
  list-style: none;
  padding: 0;
  margin: 0;
}

// Max width
$width: 100%;
$maxWidth: 1440px;
// Max width