
// .table-action-gap-container {
//     column-gap: 5px;
// }
// .space {
//     margin-right: 70px;
// }


// .draggable-table--new {
//     .single-checkbox-tab {
//         max-width: 30%;
//         flex: 0 0 30%;
//     }
//     .single-checkbox-tab {
//     }
// }
.episode-season-table{
    .description-column{
        max-width: 8% !important;
    }
}

