@import "./variable.scss";
@import "./fonts.scss";
// Bootrsrap Overwrite CSS
@media (min-width: 1200px) {
  .container {
    max-width: $maxWidth;
  }
}
.custom__container {
  width: calc(100% - 30px);
  max-width: $maxWidth;
  margin: 0 auto;
}
a {
  color: #030dcf;
}

// Bootsrap overwrite

.form-group {
  margin-bottom: 22px;
}

.form-control {
  border: 1px solid #D9DCE1;
  border-radius: 0.3125rem;
  color: $black;
  font-size: $fontbase;
  min-height: calc(1.5em + 0.75rem + 0.5rem);
  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &:focus {
    box-shadow: none;
    border-color: $gray;
  }
  
  &.form-control-flat {
    border-radius: 0;
  }
}
textarea.form-control {
  padding: 0.9375rem 0.75rem;
  resize: none;
}
select,
select.custom-select {
  appearance: none;
  background: $white url(../images/select-icon.svg) no-repeat;
  background-position: calc(100% - 15px) center;
  padding-right: 30px;
  cursor: pointer;
}
.form-control-note {
  font-style: italic;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 16px;
  color: #979797;
  margin-top: 0.3125rem;
}
.form-control-normal-note {
  font-size: 14px;
  line-height: 17px;
  color: #1A1F36;
}
.invalid-feedback {
  display: block;
  // font-size: 14px;
  color: $red;
  &:empty {
    display: none;
  }
}
.success-feedback {
  display: block;
  font-size: 14px;
  color: $green;
  width: 100%;
  margin-top: 0.25rem;
  &:empty {
    display: none;
  }
}

.label-primary {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1A1F36;
  margin-bottom: 10px;

  .required {
    color: $red;
  }
}

.label-secondary {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 6px;

  .required {
    color: $red;
  }

  &.dark {
    color: $white;
  }
}

.btn {
  font-weight: 500;
  font-size: 0.875rem;
  padding: 0.375rem 0.5rem;
  border-radius: 0.3125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover,
  &:focus {
    box-shadow: none;
    opacity: .9;
  }
  &:disabled {

  }
}
.btn__icon {
  margin-right: 0.5rem;
}
.btn-lg {
  font-size: 1rem;
  padding: 0.6875rem 1rem;
}
 
.btn__purple {
  color: $white;
  background: $pink;
  border-color: $pink;
  
  &:hover,
  &:focus,
  &:active {
    color: $white;
    background: $pink;
    border-color: $pink;
  }
}
.btn__green {
  color: $white;
  background: $secondaryColor;
  border-color: $secondaryColor;
  
  &:hover,
  &:focus,
  &:active {
    color: $white;
    background: $secondaryColor;
    border-color: $secondaryColor;
  }
}
.btn__purple--outline {
  color: $pink;
  background: $white;
  border-color: $white;

  &:hover,
  &:focus,
  &:active {
    color: $pink;
    background: $white;
    border-color: $white;
  }
}
.btn__default {
  color: #3C4257;
  background: $white;
  border-color: $white;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
  0px 0px 0px 1px rgba(60, 66, 87, 0.16),
  0px 1px 1px rgba(0, 0, 0, 0.12);

  &:hover,
  &:focus,
  &:active {
    color: #3C4257;
    background: $white;
    border-color: $white;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
    0px 0px 0px 1px rgba(60, 66, 87, 0.16),
    0px 1px 1px rgba(0, 0, 0, 0.12);
  }
}
.btn__link {
  color: $pink;
  text-decoration: none;
  padding: 0 0.5rem;
  &:hover,
  &:focus,
  &:focus {
    color: $pink;
   }
}
.btn__link--underline {
  color: $pink;
  text-decoration: underline;
  &:hover,
  &:focus,
  &:focus {
    color: $pink;
    text-decoration: underline;
  }
}
.btn__link--deafult {
  color: $black;
  text-decoration: none;
  &:hover,
  &:focus,
  &:focus {
    color: $black;
    text-decoration: none;
  }
}
.btn__link--danger {
  color: $red;
  text-decoration: none;
  &:hover,
  &:focus,
  &:focus {
    color: $red;
    text-decoration: none;
  }
}


.card {
  border: 0;
  box-shadow: 5px 5px 20px #E5E5E5;
  border-radius: 6px;
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }

  &.none-shadow {
    box-shadow: none;
  }

  &.transparent {
    background-color: transparent;
  }

  .card-header {
    padding: 12px 25px;
    background-color: #fff;
    border-bottom: 1px solid #E5E5E5;
    min-height: 65px;
    border-radius: 6px 6px 0 0;

    &.card-header-lg {
      padding: 12px 45px;

      @media (max-width: 767px) {
        padding: 12px 15px;
      }
    }

    .card-title {
      font-weight: $bold;
      font-size: 22px;
      line-height: 26px;
      color: $black;
      margin: 0;

      @media (max-width: 767px) {
        font-size: 18px;
      }

      &.card-title-sm {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .card-body {
    padding: 20px 45px;

    @media (max-width: 767px) {
      padding: 20px 15px;
    }

    &.card-body-sm {
      padding: 25px;

      @media (max-width: 767px) {
        padding: 15px;
      }
    }
  }
}
// Bootsrap overwrite

// Base 
.cursor {
  cursor: pointer;
}
.underline {
  text-decoration: underline !important;
}

// Common Css

// Common Css

// Loader
.anticon-spin {
  animation: loadingCircle .6s infinite linear;
}

.loader__full {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 10;

  &::after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
 
  &.loader__cover {
    position: absolute;
  }
}

// Loader

// Slick orverwrite
.slick-arrow {
  z-index: 1;
  &.slick-prev,
  &.slick-next {
    width: 60px;
    height: 60px;
    &::before {
      font-family: inherit;
      font-size: inherit;
      opacity: 1;
    }
  }
  &.slick-prev {
    left: 0;
    background: url('../images/slick-left.svg') no-repeat;
    background-size: cover;
  }
  &.slick-next {
    right: 0;
    background: url('../images/slick-right.svg') no-repeat;
    background-size: cover;
  }
}
// Slick orverwrite

// Custom Checkbox
.custom__checkbox {
  display: inline-block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1A1F36;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  .custom__checkbox--input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.custom__checkbox--check {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  border-radius: 4px;
  background-color: #fff;
  // border: 1px solid #000;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
  0px 0px 0px 1px rgba(60, 66, 87, 0.16),
  0px 1px 1px rgba(0, 0, 0, 0.12);
}

.custom__checkbox {
  .custom__checkbox--input:checked~.custom__checkbox--check {
    background-color: $pink;
  }
}

.custom__checkbox--check:after {
  content: "";
  position: absolute;
  display: none;
}

.custom__checkbox {
  .custom__checkbox--input:checked~.custom__checkbox--check:after {
    display: block;
  }

  .custom__checkbox--check:after {
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

// Custom Checkbox 

// Custom Checkbox Only
.custom__checkbox--only {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  padding-left: 0;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1A1F36;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 14px;
  height: 14px;
 
  .custom__checkbox--only-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:disabled {
      pointer-events: none;
      opacity: .8;
    }
  }
}

.custom__checkbox--only-check {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #dadbde;
}

.custom__checkbox--only {
  .custom__checkbox--only-input:checked~.custom__checkbox--only-check {
    background-color: $pink;
    border: 1px solid $pink;
  }
}

.custom__checkbox--only-check:after {
  content: "";
  position: absolute;
  display: none;
}

.custom__checkbox--only {
  .custom__checkbox--only-input:checked~.custom__checkbox--only-check:after {
    display: block;
  }

  .custom__checkbox--only-check:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

// Custom Checkbox Only

// Radio
.custom__radio {
  display: inline-block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1A1F36;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .custom__radio--input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
}

.custom__radio--check {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: $white;
  border: 1px solid #dadbde;
  border-radius: 50%;
}

.custom__radio {
  
  .custom__radio--input:checked~.custom__radio--check {
    background-color: $white;
  }
}

.custom__radio--check:after {
  content: "";
  position: absolute;
  display: none;
}

.custom__radio {
  .custom__radio--input:checked~.custom__radio--check:after {
    display: block;
  }

  .custom__radio--check:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $pink;
  }
}

// Radio

// Radio Btn group
.custom__radio__group {
  position: relative;
  display: inline-block;
  .custom__radio__group--button {
    appearance: none;
    opacity: 0;
    position: absolute;
    z-index: -9999;
    &:checked + .custom__radio__group--label {
      color: $pink;
      box-shadow: 0px 2px 5px rgba(200, 0, 255, 0.08),
      0px 0px 0px 1px rgba(200, 0, 255, 0.16),
      0px 1px 1px rgba(200, 0, 255, 0.12);
    }
  } 
  .custom__radio__group--label {
    font-weight: 500;
    font-size: 0.875rem;
    padding: 0.375rem 0.5rem;
    border-radius: 0.25rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #3C4257;
    background: $white;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
    0px 0px 0px 1px rgba(60, 66, 87, 0.16),
    0px 1px 1px rgba(0, 0, 0, 0.12);
    margin: 0 6px;
    transition: all 0.2s ease;
  }
  .category-label {
    margin: 0 6px 15px 0;
  }
}
// Radio Btn group

// Antd Css overwrite
.ant-notification {
  .cm-notification {
    .ant-notification-notice-description {
      padding-right: 20px;
    }
  }
}
// Antd Css overwrite 


// Impersonate
.impersonate {
  background: #F7D9FF;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .impersonate__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .impersonate__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $secondaryColor;
    text-align: center;
    .impersonate__link {
      color: $secondaryColor;
      font-weight: 500;
      font-size: 14px;
      background: transparent;
      background-image: none;
      border: 0;
      padding: 0;
      text-decoration: underline;
    }
  }
}
// Impersonate

// Main
$header: 57px;
$impersonate: 50px;
$tabs: 38px;

.main {
  &[role="header"] {
    padding-top: 57px;
  }
  &[role="header-with-tabs"] {
    padding-top: 104px;
    &[impersonate="impersonate"] {
      padding-top: 104px + 50px;
    }
  }
  &[role="head"] {
    padding-top: 66px;
  }
  &[role="tabs"] {
    padding-top: 104px;
  }
  .main__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
}
// Main

.head {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 66px;
  background: $white;
  border-bottom: 1px solid #D9DCE1;
  &.head__fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  .head__container {
    width: calc(100% - 30px);    
    max-width: $maxWidth;
    margin: 0 auto;
    &.head__container--full {
      max-width: 100%;
    }
  }
  .head__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
  }
  .head__left {
    display: flex;
    align-items: center;
  }
  .head__close {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .btn__close {
      &:hover {
        background: #f2f2f2;
      }
    }
  }
  .head__divider {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    width: 1px;
    height: 24px;
    background: #D9DCE1;
    @media (min-width: 1024px) {
      margin: 0 20px;
    }
  }
  .head__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #1A1F36;
  }
  .head__right {
    display: inline-flex;
    align-items: center;
    .btn__head {
      white-space: nowrap;
      margin-left: 15px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

// Page header
.page__header {
  width: 100%;
  padding: 30px 0;
  // margin: 30px 0 20px;
  .page__header--container {
    width: calc(100% - 30px);
    max-width: $maxWidth;
    margin: 0 auto;
  }
  .page__header--row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 35px;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  .page__header--left {
    .page__header--title {
      font-family: $SFProDisplay;
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;
      color: #1A1F36;
      margin-bottom: 0;
      @media (min-width: 768px) {
        font-size: 28px;
        line-height: 33px;
      }
    }
    .page__header--subtitle {
      font-family: $SFProDisplay;
      font-size: 18px;
      line-height: 22px;
      color: #1A1F36;
      margin: 12px 0 20px;
      @media (min-width: 768px) {
        font-size: 22px;
        line-height: 26px;
      }
      a {
        color: $pink;
        text-decoration: none;
      }
    }
  } 
  .page__header--right {
    margin-top: 20px;
    @media (min-width: 768px) {
      margin-top: 0;
    }
    .btn {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      +.btn {
        margin-top: 20px;
        @media (min-width: 768px) {
          margin-top: 0;
        }
      }
    }
  }
}
// Page header

// Table 
.table__responsive--custom {
  
}
table.table__custom {
  margin-bottom: 0;
   
  > :not(:first-child) {
    border-top: 0;
  }
  thead {
    border-bottom: 1px solid #E3E8EE;
    th {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      text-transform: uppercase;
      color: #1A1F36;
      padding: 0.75rem 0.5rem;
      white-space: nowrap;
    }
  }
  tbody {
    tr {
      &:hover {
        background: #fafafa;
      }
      td {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1A1F36;
        padding: 0.625rem 0.5rem;
        vertical-align: middle;
        white-space: nowrap;
      }
    }
  }
  .table__selection--column {
    width: 20px;
  }
  .table__cell--first {
    width: 20%;
  }

  .table__cell--last {
    text-align: right;
  }
  .table__status {
    &.active {
      color: $secondaryColor;
    }
    &.inactive {
      color: $thirdColor;
    }
  }
}
.table__blank {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  .table__blank--text {
    color: #697386;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
}

.table-drag-handle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  margin-right: 10px;
}
// Table 

// Table Pagination
.table__pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 25px 0;
  .table__pagination--results {

  }
  .table__pagination--nav {
    .table__pagination--prev,
    .table__pagination--next {
      margin-left: 8px;
    }
  }
}
// Table Pagination

// Base 

  
// React Modal Css
.ReactModal__Body--open {
  overflow: hidden;
  // padding-right: 17px;
}

.ReactModal__Body--open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.modal {
  background: rgba(0, 0, 0, 0.5);
}

.ReactModal__Overlay--after-open.modal {
  display: block;
}
 

.react-modal {
  .modal-content {
    border: 0;
    border-radius: 10px;
    &.modal-content-flat {
      border-radius: 0;
    }
  }

  .modal-dialog {
    max-width: 600px;

    &.react-modal-dialog-xs {
      max-width: 420px;
    }

    &.react-modal-dialog-sm {
      max-width: 700px;
    }

    &.react-modal-dialog-sm-md {
      max-width: 850px;
    }

    &.react-modal-dialog-md {
      max-width: 960px;
    }

    &.react-modal-dialog-lg {
      max-width: 1024px;
    }

    &.react-modal-dialog-xl {
      max-width: 1200px;
    }
  }
}

.react-modal-header {
  padding: 20px;
  position: relative;

  .react-modal-title {
    font-family: $SFProDisplay;
    font-weight: $bold;
    font-size: 16px;
    line-height: 22px;
    color: $lightblack;
    margin-bottom: 0;
    &.react-modal-title-style-2 {
      color: #000000;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
     
      margin-bottom: 10px;
    }
  }

  .react-modal-subtitle {
    font-size: 16px;
    line-height: 25px;
    color: $black;
    margin-bottom: 0;
    &.react-modal-subtitle-style-2 {
     
      font-weight: 400;
      font-size: 17px;
      line-height: 26px;
      color: #000000;
    }
  }

  .react-modal-close {
    position: absolute;
    top: 8px;
    right: 15px;
    z-index: 1;
  }
}

.react-modal-body {
  padding: 20px;
  background: #F7FAFC;
  &.react-modal-body-style-2 {
    background: #fff;
  }
}
.react-modal-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}
.react-modal-body-text {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.react-modal-footer {
  padding: 20px;
  border-radius: 0 0 10px 10px;
  background: $white;
  display: flex;
  justify-content: flex-end;
}

// React Modal Css

// Genral Css

// material-Lable style
.material-textfield {
  position: relative;

  .material-textfield-label {
    font-size: 14px;
    // line-height: normal;
    color: #000000;
    position: absolute;
    pointer-events: none;
    left: 14px;
    top: 16px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    margin-bottom: 0;
  }

  .material-textfield--input {

    &:focus,
    // &:read-only,
    &:disabled,
    &:valid {
      padding-top: 20px;
    }

    // &:read-only ~ .material-textfield-label,
    &:disabled~.material-textfield-label,
    &:valid~.material-textfield-label,
    &:focus~.material-textfield-label {
      top: 8px;
      left: 14px;
      font-weight: $bold;
      font-size: 12px;
      // line-height: normal;
      color: $black;
    }

    &:disabled~.material-textfield-label {
      color: #9B9B9B;
      top: 8px;
      font-size: 14px;
      // line-height: normal;
      font-weight: 700;
    }
  }
}

// material-Lable style
 
// Tabs
.tabs__links {
  display: flex;
  align-items: center;
  position: relative;
  background: $white;
  border-bottom: 1px solid #E5E5E5;
  width: calc(100% - 30px);
  max-width: $maxWidth;
  margin: 0 auto;
  &.tabs__links--space {
    margin-bottom: 20px;
  }
  &.tabs__links--full {
    width: 100%;
  }
  .tabs__link {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #697386;
    background: transparent;
    background-image: none;
    text-decoration: none;
    position: relative;
    padding: 9px 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0;
    border-bottom: 2px solid transparent;
    margin-right: 26px;
    &:hover,
    &:focus {
      outline: none;
    }
    &:last-child {
      margin-right: 0;
    }
    &.active {
      color: $secondaryColor;
      border-bottom: 2px solid $secondaryColor;
    }
  }
}

.tabs__underline {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 22px;   
  .btn__tab {
   
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #697386;
    background: transparent;
    background-image: none;
    text-decoration: none;
    position: relative;
    padding: 9px 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid transparent;
    margin-right: 26px;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: $black;
      border-bottom: 2px solid $black;
    }
  }
}

// Tabs

// Dropzone
.dropzone {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  .dropzone__area {
    background: $white;
    border: 1px dashed #D9DCE1;
    border-radius: 5px;
    width: 100%;
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.dropzone__grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  .dropzone__grid--sorter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .dropzone__grid--item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    width: 50px;
    height: 50px;
    margin-right: 15px;
    margin-bottom: 15px;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
  }
}
.dropzone__grid--preview {
  position: relative;
  width: 100%;
  height: 50px;
  background: #d2d6dc;
  border-radius: 4px;
  > .preview__thumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    display: block;
    // position: absolute;
    // top: 0;
    // left: 0;
  }
}
.dropzone__grid--remove {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  .btn__remove {
    line-height: normal;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    padding: 4px;
    border-radius: 100px;
    position: absolute;
    z-index: 1;
    background: #f04533;
    // background: #000;
    svg {
      position: absolute;
      z-index: -1;
      pointer-events: none;
    }
  }
}
// Dropzone

// Custom Container
.custom__container {
  .custom__wrap { 
    width: 100%;
    max-width: 415px;
    margin: 0 auto;
    padding: 20px 0;
    @media (min-width: 1024px) {
      padding: 40px 0;
    }
  }
}
// Custom Container

// Top Navigation
.top__navigation {
  display: flex;
  align-items: center;
  position: relative;
  background: #FAFAFA;
  padding: 15px 0;
  width: 100%;
  &.top__navigation--fixed {}
}
.top__navigation--container {
  width: calc(100% - 30px);
  max-width: $maxWidth;
  margin: 0 auto;
}
.top__navigation--row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .top__navigation--left {
     display: flex;
     align-items: center;
    .top__navigation--logo {
      margin-right: 25px;
      .top__navigation--brand {
        > img {
          max-width: 34px;
        }
      }
    }
  }
  .top__navigation--right {
    .top__navigation--nav {
      display: flex;
      align-items: center;
      .nav__link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-left: 15px;
        padding: 0.3125rem;
      }
    }
  }
}
.top__navigation--dropdown {
  .profile__wrapper {
    padding: 12px;
    border-bottom: 1px solid #D9DCE1;
    min-width: 200px;
    .profile__name {
      color: $black;
      font-size: 14px;
      font-weight: 600;
    }
    .profile__role {
      font-size: 12px;
    }
  }
  .top__navigation--dropdown-link,
  .top__navigation--dropdown-btn {
    text-decoration: none;
    font-size: 14px;
    color: $pink;
    font-weight: 600;
    &:hover {
      color: $pink;
    }
  }
  
}
// Top Navigation

// Trial Period
.trial__period {
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
  .trial__period--btn {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: $secondaryColor;
    background: #F7D9FF;
    border: 0;
    background-image: none;
    border-radius: 100px;
    padding: 6px 15px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &.disabled,
    &:disabled {
      pointer-events: none;
      opacity: .5;
      user-select: none;
    }
  }
}
// Trial Period

// Billing
.billing__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #D9DCE1;
  .billing__header--title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #1A1F36;
    margin-bottom: 0;
  }
}
.payment__card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #D9DCE1;
  margin-bottom: 30px;
  .payment__card--info {
    display: flex;
    align-items: center;
  }
  .payment__card--card {
    margin-right: 12px;
  }
  .payment__card--number {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  .payment__card--update {
     display: flex;
     align-items: flex-end;
  }
}

.blank__state {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  .blank__state--text {
    color: #697386;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
  }
}
 
// Billing

// Domain Setup
.domain__setup {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px 0;
  .btn__domain {
    margin: 0 9px;
  }
}
// Domain Setup
 
.pricing__module {
  padding: 0 0 15px;
  .pricing__module--inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pricing__module--save-money {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $secondaryColor;
    background: rgba(200, 0, 255, 0.1);
    border-radius: 100px;
    padding: 2px 10px;
    margin: 10px 0 15px;
    margin-left: calc(100% - 296px);
  }
  .pricing__module--text {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
  }
}

// Coupon Code
.coupon__code {
  position: relative;
  display: flex;
  align-items: center;
  .coupon__code--btn {
    margin-left: 15px;
  }
}
.powered__by__stripe {
  margin-top: 10px;
  max-width: 120px;
}
// Coupon Code

// Image Picker
.image__picker {
  background: #F7FAFC;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 280px;
  .image__picker--thumb {
    width: 100%;
    height: 0;
    position: relative;
    padding-top: 66.66%;
    > img {
      position: absolute;
      top: 0;
      left: 0;
      object-position: center;
      object-fit: contain;
      padding: 10px;
      width: 100%;
      height: 100%;
      max-width: 100%;
      display: block;
    }
  }
  .image__picker--controls {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #D9DCE1;
    .btn {
      width: 100%;
      border: 0;
      border-radius: 0;
      border-right: 1px solid #D9DCE1;
      &:last-child {
        border-right: 0 solid #D9DCE1;
      }
    }
  }
}
// Image Picker
 
// Ck Editor 5
.ck__editor--customized {
  .ck {
    &.ck-toolbar {
      border: 1px solid #D9DCE1;
      background: $white;
      border-radius: 4px 4px 0 0 !important;
    }
  }
  .ck-editor__editable {
    border: 1px solid #D9DCE1 !important;
    border-top: 0 !important;
    border-radius: 0 0 4px 4px !important;
    min-height: 300px;
    max-height: 300px;
    &:focus {
      box-shadow: none !important;
    }
  }
}
// Ck Editor 5
 

// Phone Moduale
.react-tel-input {
  .form-control {
    height: 44px;
    width: 100%;
  }
}
// Phone Moduale

.short-desc {
  max-width: 90%;
  white-space: normal;
}
.textarea-text-length {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1A1F36;
  text-align: right;
  margin-top: 10px;
}

// Dropzone style start

.dropzone-file {
  border-radius: 5px;
  border: 1px dashed #D9DCE1;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  .add-btn {
      // background-color: ;
  }
  .csv-reader-uploader {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    input {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }
}

.upload-content {
  height: 50px;
  width: 50px;
  position: relative;
  margin-top: 15px;
  button {
    height: 15px;
    width: 15px;
    padding: 5px;
    font-size: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F04533;
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 50px;
    border: none;
  }
  img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    object-position: center;
  }
}
.ant-select-dropdown {
  z-index: 9999;
}
// Dropzone style end


.navigation-right {
  display: flex;
}


.checkbox-listing {
  display: flex;
  flex-direction: column;
  .ant-checkbox-wrapper {
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    &:after {
      display: none;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  .category-checkbox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.small-table-block {
  padding: 60px 0 0;
}
.container-small {
  max-width: 800px;
  margin: 0 auto;
}

.sync-btn {
  padding: 0;
  background: transparent;
  border: none;
}
.custom-checkbox-update {
  padding: 1px 0 0 25px !important;
}

.draggable-table--new .hide-app-icon-td,
.draggable-table--new .single-checkbox-tab {
  flex: 0 0 15%;
  max-width: 15%;
  text-align: center;
  justify-content: center;
}
.draggable-table--new .draggable-continer-head .action-description {
  flex: 0 0 10%;
  max-width: 10%;
  padding: 0.75rem 0.5rem;
}

.draggable-table--new .drag-table-container .draggable-row .description-column {
  flex: 0 0 10%;
  max-width: 10%;
}
.column-copytable {

}
.checkbox-two-items {
  margin-left: 22px;
  @media (max-width: 999px) {
    margin-left: 15px;
  }
}
.draggable-table--new .table>:not(:first-child) {
  border-top: #e3e8ee;
}

.pointer-table-item {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}


.image-space {
  display: flex;
  align-items: center;
  svg {
    margin-left: 6px;
  }
  // .ant-popover-inner-content{
  //   padding: 0px
  // }
}

.info-overlay {
  z-index: 9999;
  .ant-popover-inner-content {
    padding: 5px;
    .image-popover {
      width: 150px;
    }
  }
}

.icon-small-v {
  transform: scale(0.8);
}