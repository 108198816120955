.draggable-continer-head {
    display: flex;
    padding: 5px 0;
    border-bottom: 1px solid #E3E8EE;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
    color: #1A1F36;
    white-space: nowrap;

    .title-block {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0.75rem 0.5rem;
    }
    .status {
        flex: 0 0 10%;
        max-width: 10%;
        padding: 0.75rem 0.5rem;
    }
    .action-description {
        flex: 0 0 15%;
        max-width: 15%;
        padding: 0.75rem 0.5rem;
    }
}

.drag-table-container .draggable-row {
    display: flex;
    border-bottom: 1px solid #E5E5E5;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    .btn {
        padding: 0;
    }
  
    .title-block {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0.75rem 0.5rem;
      .drag-icon {
        height: 10px;
        margin-right: 5px;
      }
    }
  
    .status-column {
      flex: 0 0 10%;
      max-width: 10%;
      padding: 0.75rem 0.5rem;
      .active {
        color: #5ABDCE;
      }
    }
  
    .description-column {
      flex: 0 0 30%;
      max-width: 30%;
      padding: 0.75rem 0.5rem;
    }
  
    .table__cell--last {
      flex: 0 0 10%;
      max-width: 10%;
      padding: 0.75rem 0.5rem;
      text-align: right;
    }
  }

  .timeline-block {
    border-top: none !important;
  }