// node_modules css
@import 'antd/dist/antd.css';
@import 'bootstrap/dist/css/bootstrap.min.css';
// node_modules css
@import '../theme/global.scss';
@import '../theme/variable.scss';

body {
  font-family: $SFProText, sans-serif;
  font-size: $fontbase;
  color: $black;
}